<template>
  <div class="mode-center">
    <div class="pt-top"></div>
    <div class="text text-white text-big flex flex-wrap flex-row justify-end">
      <div class="mb-4 mx-nav flex-shrink-0">
        <router-link to="/stappenplan" class="border-b border-white hover:text-soft-blue hover:border-soft-blue py-2 px-1 transition duration-300 ease-in-out">
          Stappenplan
        </router-link>
      </div>
      <div class="mb-4 mx-nav flex-shrink-0">
        <router-link to="/inzichten" class="border-b border-white hover:text-soft-blue hover:border-soft-blue py-2 px-1 transition duration-300 ease-in-out">
          Inzichten
        </router-link>
      </div>
      <div class="mb-4 mx-nav flex-shrink-0">
        <a href="https://www.degoedezaak.org/#steun" target="_blank" class="rounded-sm font-bold text-dark bg-malibu-500 hover:bg-malibu-600 py-2 px-2 transition duration-300 ease-in-out">
          Steun DeGoedeZaak
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'

var lv = {}
lv.thisVue = null

export default {
  name: "nav_main",
  props: [],
  data() {
    return {
      preload_show: true
    }
  },
  mounted() {
    lv.thisVue = this
  }
}
</script>

<style>
.navicon{
  position: relative;
  top:-2px;
}
</style>

