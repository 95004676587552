<template>
<div class="foot minh57 bg-white">
  <div class="mode-center h-full flex flex-wrap items-center minh57">
    <div class="mx480:w-full mx480:pt-3 mx480:pb-3">
      <!--  

      <router-link to="/" class="relative block text text-malibu-500 text-logo">
        DeGoedeZaak          
        <div class="pos-g text-dark opacity-0 hover:opacity-100 transition duration-300 ease-in-out">
          DeGoedeZaak          
        </div>
      </router-link>
      -->
      <router-link to="/" class="relative block">
        <img src="@/assets/img/logo_footer.png" />
      </router-link>
    </div>
    <div class="flex-grow flex flex-wrap flex-row mn480:justify-end py-4 mx480:py-0">
      <a 
        class="text text-foot hover:text-malibu-600 mn480:ml-5 mx480:mr-5 ptfootnav mx480:pb-4 transition duration-300 ease-in-out focus:outline-none"
        v-for="(item, index) in data" :key="index"
        :href="item.link"
        target="_blank"
      >
        {{item.text}}
      </a>
      <a class="text text-foot text-blew mn480:ml-5 mx480:mr-5 ptfootnav mx480:pb-4 transition duration-300 ease-in-out focus:outline-none"
        target="_blank" href="https://multitude.nl"
      >
        Powered by Multitude
      </a>
    </div>
  </div>
</div>
</template>

<script>
import { globals } from '@/modules/globals'

export default {
  name: "footer_fixed",
  props: [],
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    this.data = globals.api_data['footer_items']
  }
}
</script>

<style>
  .foot{
    position: fixed;
    bottom:0px;
    left:0px;
    width:100%;
  }
  .minh57{
    min-height: 57px;;
  }
</style>

