<template>
  <div class="bg-white fixed top-0 w-full z-10">
    <div class="mode-center relative z-50">
      <div class="nav-mobile flex items-center">

        <div class="inline-block">  
          <router-link to="/" class="relative block">
            <img src="@/assets/img/logo_footer.png" />
          </router-link>     
        </div>

        <div class="ml-auto flex items-center">
          <div class="relative inline-block">
            <img src="@/assets/img/hamburger-open.png" v-show="!menu_mobile_open"/>
            <img src="@/assets/img/hamburger-close.png" v-show="menu_mobile_open" />
            <div class="pos-center menuclick" @click="menu_toggle"></div>
          </div>
        </div>


      </div>
    </div>
    <div class="mobilemenu bg-white" v-show="menu_mobile_open">
      <div class="wrapmenu text">
        <div class="mode-center">
          <div class="p-12"></div>
          <div class="text-mobile-menu">
            <router-link to="/stappenplan">
              <span>
                Stappenplan
              </span>
              <span class="mobilemenuicon">
                <svg class="w-6 h-6 inline-block ml-2 nudgeiconmobile" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </span>
            </router-link>
            <div class="pt-6"></div>
            <router-link to="/inzichten">
              <span>
                Inzichten
              </span>
              <span class="mobilemenuicon">
                <svg class="w-6 h-6 inline-block ml-2 nudgeiconmobile" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </span>
            </router-link>
            <div class="pt-6"></div>
            <a href="https://www.degoedezaak.org/#steun" target="_blank" class="  text-malibu-500">
              <span>
                Steun DeGoedeZaak
              </span>
              <span class="mobilemenuicon">
                <svg class="w-6 h-6 inline-block ml-2 nudgeiconmobile" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </span>
            </a>
            <div class="pt-6"></div>

              <a 
                class="text text-foot hover:text-malibu-600 pt-4 transition duration-300 ease-in-out focus:outline-none block"
                v-for="(item, index) in data" :key="index"
                :href="item.link"
                target="_blank"
              >
                {{item.text}}
              </a>
            
          </div>
        </div>
      </div>
      <div class="powered pb-6 bg-white">
        <div class="mode-center">
          <a class="text text-foot text-blew focus:outline-none"
            target="_blank" href="https://multitude.nl"
          >
            Powered by Multitude
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'

var lv = {}
lv.thisVue = null

lv.body = document.getElementsByTagName('BODY')[0]

export default {
  name: "nav_mobile",
  props: [],
  data() {
    return {
      menu_mobile_open: false,
      data: {}
    }
  },
  mounted() {
    lv.thisVue = this

    this.data = globals.api_data['footer_items']

    EventBus.$on('menu_close', (e) => {
      this.menu_close()
    })
  },
  methods: {
    menu_close(){
      this.menu_mobile_open = false
      lv.body.style.overflowY = 'scroll'
    },
    menu_toggle(){
      if(this.menu_mobile_open){
        this.menu_mobile_open = false
        lv.body.style.overflowY = 'scroll'
      }else{
        this.menu_mobile_open = true
        lv.body.style.overflowY = 'hidden'
      }
      console.log('open: ' + this.menu_mobile_open)
    }
  }
}
</script>

<style>
  .nav-mobile{
    min-height: 57px;
  }
  .menuclick{
    width: 200%;
    height:200%;
  }
  .mobilemenu{
    position:fixed;
    height: 100vh;
    width: 100vw;
    top:0px;
    left:0px;
  }
  .powered{
    position: fixed;
    bottom:0px;
    left:0px;
    width: 100%;
  }
  .wrapmenu{
    overflow-y: auto;
  }
  .nudgeiconmobile{
    position: relative;
    top:-5px;
  }
</style>

