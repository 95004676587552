<template>
  <div id="app" class="pos-r minh100v pb-32 mx720:pb-12">

    <div class="pos-g bg-dark"></div>
    <div class="pos-r minh100v">

      <nav_main class="mx720:hidden" />

      <div class="p-6 mn720:hidden" ></div>
      <nav_mobile class="mn720:hidden" />

      <div class="minhview">
        <transition
          appear
          :css="false"
          mode="out-in"
          @appear="appear"
          @enter="enter"
          @leave="leave"
        >

          <router-view :key="$route.fullPath" /> 

        </transition> 

        <!--  
        <router-view :key="$route.fullPath" /> 
        -->

      </div>

      <!--  
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/home_2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/inzicht_2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/kit_intro_2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/kit_2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/feedback_closed_2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/feedback_open_2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/feedback_closed_3.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/menu.png" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      -->



    </div>

    <div class="pos-g bg-dark hidden opacity-0" id="curtain_main"></div>

    <div class="bg-purple-600 rounded-lg text-white pt-2 pb-1 px-2 live_update" v-if="show_live_update">
      <svg class="w-8 h-8 navicon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
    </div>

    <footer_fixed class="mx720:hidden" />

    <preload />
    <sneekpreview v-if="preview_show" />


    <div class="pos-g bg-white hidden opacity-0" id="hider_main"></div>
  </div>
</template>


<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
const scrollPlugin = ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin)

import preload from '@/components/preload.vue'
import sneekpreview from '@/components/sneekpreview.vue'
import nav_main from '@/components/ui/nav_main.vue'
import nav_mobile from '@/components/ui/nav_mobile.vue'
import footer_fixed from '@/components/ui/footer_fixed.vue'

var lv = {}
lv.thisVue = null

export default {
  name: "app",
  components: {
    preload,
    sneekpreview,
    nav_main,
    nav_mobile,
    footer_fixed
  },
  data() {
    return {
      preview_show: false,
      show_live_update: false
    }
  },
  mounted () {

    lv.thisVue = this

    if(globals.preview_allowed){
      control_main.preloader_done()
    }else{
      this.preview_show = true
    }

    EventBus.$on('live_update', (e) => {
      if(this.show_live_update){
        this.show_live_update = false
      }else{
        this.show_live_update = true
      }
    })

    // hijack a href to router
    window.addEventListener('click', event => {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
        if (metaKey || altKey || ctrlKey || shiftKey) return
        if (defaultPrevented) return
        if (button !== undefined && button !== 0) return
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        const url = new URL(target.href)
        const to = url.pathname
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault()
          this.$router.push(to)
        }
      }
    })
  },
  methods: {
    appear(el, done) {
      gsap.to('#curtain_main',{duration:0.5, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
    },
    enter(el, done) {
      gsap.to('#curtain_main',{delay:0.2, duration: 0.5, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
    },
    leave(el, done) {
      EventBus.$emit('menu_close')
      gsap.to('#curtain_main',{duration:0.5, display: 'block', opacity: 1, ease:'power3.inOut', onComplete: done })
      gsap.to(window, {delay:0.5, duration: 0.2 , scrollTo: {y: 0}, ease:'none'})
    },
  }
}


</script>

<style>

.minh100v{
  min-height: 100vh;
}
.minhview{
  min-height: 500px;
}
.live_update{
  position: fixed;
  top:15px;
  left:15px;
}

</style>
