<template>
  <div class="preload fixed w-full h-full top-0 left-0 bg-white z-50 invisible" id="sneekpreview" v-if="show_sneekpreview">
    <div class="pos-center text text-dark rounded px-8 py-8 sneekbody bg-gray-400">
      <div class="mb-4">
        <label class="text-body-1 pb-4" for="preview_code">
          Preview code
        </label>
        <input class="input-main  mt-4" id="preview_code" ref="preview_code" v-model="preview_code">
      </div>
      <div class="p-4 mb-4 rounded border border-dark text-dark font-mono font-bold flex flex-row" v-show="error_show">
        <div class="text-dark">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
        </div>
        <div class="ml-4 animate-pulse">
          {{error_message}}
        </div>
      </div>
      <div class="text-right pt-2 pb-1">
        <button class="bg-grey-light text-dark hover:bg-dark hover:text-white py-3 px-4 rounded transition duration-300 ease-in-out" @click="enter_sneek">
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'
import api from "@/modules/api"

import gsap from 'gsap'

var lv = {}
lv.thisVue = null

export default {
  name: "sneekpreview",
  components: {
  },
  props: [],
  data() {
    return {
      show_sneekpreview: true,
      preview_code: null,
      error_show: false,
      error_message: 'code onjuist'
    }
  },
  mounted() {
    lv.thisVue = this

    this.$nextTick(function () {
      lv.targ = '#sneekpreview'
      gsap.set(lv.targ,{opacity:0, visibility: 'visible'})
      gsap.to(lv.targ,{delay:0.3, opacity: 1})
    })

  },
  methods:{
    enter_sneek(){
      this.error_show = false
      if(this.preview_code !== '0090'){
        this.error_show = true
        return
      }
      sendPreviewAllow()
      control_main.preloader_done()
      this.show_sneekpreview = false
    }
  }
}

lv.dataTask = "preview_set"
lv.dataEvent = "data_preview_set"
function sendPreviewAllow(){
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false
  })
}

</script>

<style>

  .sneekbody{
    width:100%;
    max-width: 320px;
  }

</style>
