<template>
  <div class="preload fixed w-full h-full top-0 left-0 bg-white z-50" id="preloader" v-if="preload_show">
  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'

import gsap from 'gsap'

var lv = {}
lv.thisVue = null

export default {
  name: "preload",
  props: [],
  data() {
    return {
      preload_show: true
    }
  },
  mounted() {
    lv.thisVue = this

    EventBus.$on('preloader_done', (e) => {
      gsap.to('#preloader',{delay:0.3, duration: 0.6, opacity:0, ease:'power3.inOut', onComplete:function(){
        lv.thisVue.preload_show = false
      }})
    })
  }
}
</script>

<style>

</style>

